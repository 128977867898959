
.facetwp-wrapper {

	h3 {
		position: relative;
		margin: 5px 0 0 0;
		padding: 1rem 1.25rem;
		font-size: 1rem;
		color: rgb(51, 87, 97);
		border: solid 1px rgb(222, 226, 230);
		background-color: rgba(255, 255, 255, 0.25);

		i {
			position: absolute;
			top: 0;
			right: 0;
			padding: 0.75rem 1rem;
			font-size: 28px;
			color: #bbb;
		}
	}
}

.facetwp-facet {
	position: relative;
	max-height: 11rem;
}

.facetwp-type-checkboxes {
	padding: 16px !important;
	border-top: none !important;
	border-left: 1px solid rgb(222, 226, 230) !important;
	border-right: 1px solid rgb(222, 226, 230) !important;
	border-bottom: 1px solid rgb(222, 226, 230) !important;
	background-color: rgba(255, 255, 255, 0.25)

}

.facetwp-checkbox {
	margin-bottom: 8px;
	background-image: url('../img/th-checkbox.png');
	background-repeat: no-repeat;
	background-position: 0 50%;
	background-size: 16px 16px !important;
	opacity: 0;
	transition: opacity 100ms ease-in-out;
}

.facetwp-checkbox.checked {
	background-image: url('../img/th-checkbox-on.png');
}

.facetwp-depth {
	.facetwp-checkbox {
		opacity: 1;
	}
}


.facetwp-facet {
	margin-bottom: 0 !important;
	max-height: 215px;
	overflow-y: hidden;
	padding: 0 !important;

	.facetwp-expand {
		color: rgba(85, 130, 150, 0.75);
	}

	&.facetwp-type-checkboxes {
		padding: 0 !important;
	}

	&.initial-content {
		max-height: 215px;
		overflow-y: hidden;
	}

	&.hide-content {
		max-height: 215px;
		overflow-y: hidden;
	}

	&.show-content {
		max-height: none;
		overflow-y: auto;
	}
}


.facetwp-type-checkboxes {
	.facetwp-checkbox {
		margin-left: 16px;
		margin-right: 16px;

		&:first-child {
			margin-top: 16px;
		}

		&:last-child {
			margin-bottom: 24px;
		}
	}
}

.facet-subheader {
	padding: 1rem 1rem 0 1rem;
	border-left: solid 1px rgb(222, 226, 230);
	border-right: solid 1px rgb(222, 226, 230);
	background-color: rgba(255, 255, 255, 0.25);
	&__text {
		font-size: 15px;
		color: rgb(85, 130, 150);
	}
}
